<template>
	<div class="layout container">
		<Header :user-access="userAccess" />

		<main class="container">
			<slot />
		</main>

		<Cookies />
	</div>
</template>

<script setup lang="ts">
	import Header from "~/components/layouts/default/header/Header.vue";

	const { status } = useAuth();

	const nuxtApp = useNuxtApp();

	const userAccess = ref(nuxtApp.userRoles.getUserAccess());

	watch(
		() => status.value,
		(status) => {
			if (status === "unauthenticated") useRouter().push("/login");
		},
	);
</script>

<style scoped>
	@import "@/assets/scss/layouts/default/_styles.scss";
</style>
